.btn {
  width: 118px;
  height: 45px;
  // background-color: #0000fe;
  background-color: #c72c40;
  // background: #f0f0f0;
  // border: 1px solid #acacac;
  // border-radius: 4px;
  border: none;
  border-radius: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 750px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.active {
  background: #000;
  color: #fff;
}

.isDisabled {
  background: rgb(174, 174, 174);
  color: #fff;

  &:hover {
    cursor: not-allowed;
  }
}
