.page {
  padding: 2.5rem 2rem;
  padding-bottom: 0;

  .box1 {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
    object-fit: contain;

    .imgBox {
      width: 10rem;
      height: 10rem;
      margin-right: 3rem;

      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }

    .box2 {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .text1 {
        font-family: "Inter";
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0.8rem;
      }
      .text2 {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 0.7rem;
      }
    }
  }

  .box3 {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 3rem;
  }
  .descriptionMobile {
    display: none;
  }
}

@media (max-width: 750px) {
  .page {
    .box1 {
      .box2 {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .text1 {
          font-family: "Inter";
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 0.8rem;
        }
        .text2 {
          font-family: "Inter";
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 1.4rem;
        }

        .descriptionDesktop {
          display: none;
        }
      }
    }

    .box3 {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-top: 3rem;
    }
  }

  .descriptionMobile {
    display: block !important;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    // margin-top: 6rem;
    margin-top: 1rem;
    line-height: 24px;
  }
}

.hover {
  &:hover {
    cursor: pointer;
  }
}
