.container {
  width: 100%;
  height: 90vh;
  // height: -webkit-fill-available !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .homeBtn {
    position: absolute;
    top: 63px;
    left: 80px;

    @media (max-width: 750px) {
      display: none;
    }
  }

  .contentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 614px;
    height: 618px;
    // background: #ffffff;
    border: 10px solid #dddddd;
    // box-shadow: 20px 10px 48px #dddddd;
    // border-radius: 12px;
    overflow: hidden;

    .header {
      min-height: 98px;
      // background: rgb(224, 224, 224);
      // border-radius: 12px 12px 0px 0px;
      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      align-self: center;
      // flex-wrap: wrap;

      .btn {
        width: 130px;
        height: 45px;
        // border-radius: 4px;
      }

      @media (max-width: 750px) {
        grid-template-columns: auto auto auto;
        padding: 10px 0 0 0;
        // row-gap: 15px;
        row-gap: 5px;
        // margin-bottom: 18px;
        margin-bottom: 0;

        .btn {
          width: 100px;
        }
      }
    }

    .content {
      flex: 1;
      width: 100%;
      padding-top: 20px;
      overflow: hidden;
    }

    .footer {
      width: 100%;
      height: 76px;
      background: rgb(224, 224, 224);
      display: flex;
      align-items: center;
      padding: 0 2rem;
      cursor: pointer;
      // background-color: red;

      .firstContainer {
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 24px;
          color: #000000;
        }

        .content {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #000000;
        }
      }

      .secondContainer {
        margin-left: 15px;
        flex: 1;
        margin-right: 10px;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
        }
      }

      .thirdContainer {
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-right: 20px;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #c72c40;
        }
      }

      // p {
      //   font-family: "Inter";
      //   font-style: normal;
      //   font-weight: 400;
      //   font-size: 14px;
      //   line-height: 22px;
      //   color: #000000;
      //   a {
      //     color: #1563bf;

      //     &:hover {
      //       cursor: pointer;
      //     }
      //   }
      // }

      @media (max-width: 450px) {
        padding: 0 1rem;

        .firstContainer {
          .title {
            font-size: 18px;
            line-height: 20px;
          }

          .content {
            font-size: 10px;
            line-height: 12px;
          }
        }

        .secondContainer {
          margin-left: 10px;
          margin-right: 10px;

          p {
            font-size: 11px;
            line-height: 14px;
            // line-height: 16px;
          }
        }

        .thirdContainer {
          margin-top: 10px;
          margin-right: 0px;

          p {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    @media (max-width: 750px) {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}

.displayNone {
  display: none;

  @media (max-width: 750px) {
    display: block;
  }
}

.active {
  background-color: #dc2f21;
  color: #ffffff;
}
