.page {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 3rem;

  .containerWithImage {
    display: flex;
    align-items: center;

    .image {
      width: 40%;
      max-height: 200px;
      height: fit-content;
      object-fit: contain;
    }

    .content {
      width: 50%;
      font-family: "Inter";
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .secondpara {
    padding-left: 3rem;
    width: 75%;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }

  .box3 {
    padding-left: 3rem;
    width: 100%;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    position: relative;

    @media (max-width: 750px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .box3WithAstriks::before {
    content: "* ";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    position: absolute;
    left: 25px;
    top: 0px;
    z-index: 1;
  }

  .header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;

    p {
      font-family: "Inter";
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }
  }

  .contentContainer {
    flex: 1;
    overflow: scroll;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;

    @media (max-width: 750px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .footerContainer {
    .box3 {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 750px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .box4 {
      color: white;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 1.2rem;
      background-color: #c72c40;
      padding: 1rem 1rem;

      @media (max-width: 750px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  @media (max-width: 750px) {
    padding: 0 1rem;
  }
}
