html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 62.5%; /* now 1rem = 10px */
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: #000;
    background: #fff;
  }
}

#idone {
  /* top: 100px;
  transform: scaleX(0); */
}
.btn1 {
  width: 130px;
  height: 45px;
  background-color: rgb(61, 173, 16);
}

/* we will hide last charater by adding an after element */
.apexcharts-tooltip-text-y-label::after {
  content: "";
  display: block;
  width: 12.5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: rgb(246, 246, 246) !important;
}
