.wrapper {
  width: 100%;
  // height: 100%;
  padding: 3rem;
  color: yellow;

  .content {
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: black;
    cursor: pointer;

    .title {
      font-family: "Inter";
      font-size: 21px;
      font-weight: 600;
      line-height: 25px;
      text-align: left;
    }

    .description {
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      margin-top: 1.5rem;
    }
  }

  .icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
